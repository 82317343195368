<script lang="ts">
  import { EmailAuthProvider, GoogleAuthProvider } from 'firebase/auth';
  import * as firebaseui from 'firebaseui';
  import 'firebaseui/dist/firebaseui.css';
  import { onDestroy, onMount } from 'svelte';
  import { auth } from '../firebase';

  let uiInstance: firebaseui.auth.AuthUI = null;

  onMount(() => {
    const uiConfig: firebaseui.auth.Config = {
      signInSuccessUrl: '/',
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        new GoogleAuthProvider().providerId,
        new EmailAuthProvider().providerId,
      ],
      signInFlow: 'popup',
    };

    // Initialize the FirebaseUI Widget using Firebase.
    uiInstance = new firebaseui.auth.AuthUI(auth);
    // The start method will wait until the DOM is loaded.
    uiInstance.start('#firebaseui-auth-container', uiConfig);
  });

  onDestroy(() => uiInstance.delete());
</script>

<div id="firebaseui-auth-container" class="mt-10" />
