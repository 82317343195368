// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore, } from 'firebase/firestore/lite';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyADfvilZRHbugke2_7ZIrjwD2ejjLyms-Y',
    authDomain: 'my-awesome-cocktail-recipes.firebaseapp.com',
    projectId: 'my-awesome-cocktail-recipes',
    storageBucket: 'my-awesome-cocktail-recipes.appspot.com',
    messagingSenderId: '283522004350',
    appId: '1:283522004350:web:fc009d1a1ae04f85baef2f',
    measurementId: 'G-N85NYRPN36',
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
auth.useDeviceLanguage();
