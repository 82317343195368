<script lang="ts">
  export let isFavourite;

  import Lottie from 'lottie-web';
  import type { AnimationItem } from 'lottie-web';
  import { onMount } from 'svelte';

  let targetElement;
  import { getContext } from 'svelte';

  const favFuncRef = getContext('favFuncRef');
  const remFuncRef = getContext('remFuncRef');
  let favouriteAnimation: AnimationItem;
  let likeAnimation: AnimationItem;

  onMount(() => {
    favouriteAnimation = Lottie.loadAnimation({
      container: targetElement,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/animations/favourite-animation.json',
    });

    if (isFavourite) {
      favouriteAnimation.setSpeed(2);
      favouriteAnimation.play();
    }
  });

  const addToFavourites = () => {
    console.log('add to favourites');
    favouriteAnimation.play();
    favFuncRef();
  };

  const removeFromFavourites = () => {
    console.log('remove to favourites');
    favouriteAnimation.stop();
    remFuncRef();
  };
</script>

<button
  class="target"
  bind:this={targetElement}
  on:click={() => (isFavourite ? removeFromFavourites() : addToFavourites())}
/>

<style>
  .target {
    height: 38px;
    transform: scale(1.3);
    width: 50px;
    position: absolute;
    right: 0;
    background: transparent;
    border: none;
    outline: none !important;
  }
</style>
