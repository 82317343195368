<script lang="ts">
  import IngredientCard from '../components/IngredientCard.svelte';
  import { selectedIngredient, selectedIngredientLetter } from '../store';
  import { letters } from '../constants/constants';
  import { onMount } from 'svelte';
  import IngredientDetail from '../components/IngredientDetail.svelte';

  let ingredients: string[] = [];
  let filteredIngredients: string[] = [];

  const filterIngredients = async () => {
    filteredIngredients = ingredients.filter(
      x => x.charAt(0).toLowerCase() === $selectedIngredientLetter.toLowerCase()
    );
  };

  $: openDetail = $selectedIngredient;
  $: $selectedIngredientLetter, filterIngredients();

  onMount(async () => {
    const resp = await fetch(
      `https://www.thecocktaildb.com/api/json/v2/9973533/list.php?i=list`
    );
    const data = await resp.json();

    ingredients = data.drinks.map(r => r.strIngredient1);
    filterIngredients();
  });
</script>

<div class="flex justify-center mt-4">
  <div class="relative inline-flex self-center">
    <svg
      class="text-white bg-gray-700 absolute top-0 right-0 m-2 pointer-events-none p-2 rounded"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="40px"
      height="40px"
      viewBox="0 0 38 22"
      version="1.1"
    >
      <g
        id="ZahnhelferDE—Design"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="ZahnhelferDE–Icon&amp;Asset-Download"
          transform="translate(-539.000000, -199.000000)"
          fill="#ffffff"
          fill-rule="nonzero"
        >
          <g
            id="Icon-/-ArrowRight-Copy-2"
            transform="translate(538.000000, 183.521208)"
          >
            <polygon
              id="Path-Copy"
              transform="translate(20.000000, 18.384776) rotate(135.000000) translate(-20.000000, -18.384776) "
              points="33 5.38477631 33 31.3847763 29 31.3847763 28.999 9.38379168 7 9.38477631 7 5.38477631"
            />
          </g>
        </g>
      </g>
    </svg>
    <select
      bind:value={$selectedIngredientLetter}
      class="text-2xl font-bold rounded border-2 border-gray-700 text-gray-600 h-14 w-60 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none"
    >
      {#each letters as letter}
        <option value={letter}>{letter.toUpperCase()}</option>
      {/each}
    </select>
  </div>
</div>
<div class="ingredient-container">
  {#each filteredIngredients as ingredient}
    <IngredientCard {ingredient} />
  {/each}
</div>

{#if openDetail}
  <IngredientDetail />
{/if}

<style lang="scss">
  .ingredient-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 25px;
    grid-auto-rows: 1fr;
  }
</style>
