<script lang="ts">
  import CocktailCard from '../components/CocktailCard.svelte';
  import CocktailDetail from '../components/CocktailDetail.svelte';
  import type { Cocktail } from '../models/cocktail';
  import { currentUser, favouriteCocktails, selectedCocktail } from '../store';
  import { useNavigate } from 'svelte-navigator';

  let cocktails: Cocktail[] = [];

  $: cocktails = $favouriteCocktails;
  $: openDetail = $selectedCocktail;
  $: exitOnLogout($currentUser);

  const exitOnLogout = user => {
    if (!user) {
      const navigate = useNavigate();
      navigate('/');
    }
  };
</script>

<div class="cocktail-container">
  {#each cocktails as cocktail}
    <CocktailCard {cocktail} />
  {/each}
</div>

{#if openDetail}
  <CocktailDetail />
{/if}

<style lang="scss">
  .cocktail-container {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
    grid-gap: 25px;
    grid-auto-rows: 1fr;
    justify-content: center;
  }
</style>
