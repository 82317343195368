<script lang="ts">
  import type { CocktailSearchResult } from '../models/cocktail-search-result';
  import { searchedCocktailId } from '../store';

  export let cocktail: CocktailSearchResult;

  const getCocktailDetails = () => {
    searchedCocktailId.set(cocktail.idDrink);
  };
</script>

<div class="flex items-center justify-center cursor-pointer"
  on:click={() => getCocktailDetails()}
>
  <div class="max-w-sm w-full py-2 px-3">
    <div class="bg-white shadow-xl rounded-lg overflow-hidden">
      <div class="overflow-hidden">
        <figure class="h-56 w-full">
          <img
            class="cocktail-img"
            src={cocktail.strDrinkThumb}
            alt={cocktail.strDrinkThumb}
          />
        </figure>
      </div>
      <div class="p-4">
        <p class="text-3xl text-gray-900">{cocktail.strDrink}</p>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  .cocktail-img {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    &:hover {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
</style>
