<script lang="ts">
  import Icon from 'svelte-awesome';
  import { book, infoCircle } from 'svelte-awesome/icons';
  import { Link } from 'svelte-navigator';
  import { selectedIngredient } from '../store';
  export let ingredient: string;

  const selectIngredient = () => {
    selectedIngredient.set(ingredient);
  };
</script>

<div class="flex items-center justify-center cursor-pointer">
  <div class="max-w-sm w-full sm:w-full lg:w-full py-6 px-3">
    <div class="bg-white shadow-xl rounded-lg overflow-hidden">
      <div class="overflow-hidden">
        <figure class="h-56 w-full">
          <img
            class="cocktail-img"
            src={`http://www.thecocktaildb.com/images/ingredients/${ingredient}-Medium.png`}
            alt={ingredient}
          />
        </figure>
      </div>
      <div class="p-4">
        <p class="text-3xl  text-gray-900">
          {ingredient}
        </p>
      </div>

      <div class="flex justify-around pb-4">
        <Link to={`/search-cocktail/${ingredient}`}>
          <div
            class="w-28 h-7 text-sm grid place-content-center font-semibold text-white bg-yellow-500 rounded-full"
          >
            Recipes
          </div>
        </Link>
        <div
            class="grid place-content-center w-28 h-7 text-sm text-center font-semibold text-white bg-yellow-500 rounded-full"
            on:click={() => selectIngredient()}
          >
           Info
          </div>
      </div>
     
    </div>
  </div>
</div>

<style lang="scss">
  .cocktail-img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    &:hover {
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
    }
  }
</style>
