<script lang="ts">
  import { onMount } from 'svelte';

  import CocktailCard from '../components/CocktailCard.svelte';
  import CocktailDetail from '../components/CocktailDetail.svelte';
  import type { Cocktail } from '../models/cocktail';
  import { selectedCocktail, selectedCocktailLetter } from '../store';

  let cocktails: Cocktail[] = [];

  const getCocktails = async () => {
    const resp = await fetch(
      `https://www.thecocktaildb.com/api/json/v2/9973533/popular.php`
    );
    const data = await resp.json();

    cocktails = data.drinks;
  };

  $: openDetail = $selectedCocktail;

  onMount(() => getCocktails());
</script>

<div class="cocktail-container">
  {#each cocktails as cocktail}
    <CocktailCard {cocktail} />
  {/each}
</div>

{#if openDetail}
  <CocktailDetail />
{/if}

<style lang="scss">
  .cocktail-container {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 25px;
    grid-auto-rows: 1fr;
  }
</style>
