<script lang="ts">
  import { fly, fade } from 'svelte/transition';
  import type { Cocktail } from '../models/cocktail';

  import { searchedCocktailId } from '../store';

  let ingredients: string[] = [];
  let currentCocktail: Cocktail;

  $: $searchedCocktailId, getCocktailInfo();

  const getCocktailInfo = async () => {
    if (!$searchedCocktailId) {
      currentCocktail = null;
      ingredients = [];
      return;
    }

    const resp = await fetch(
      `https://www.thecocktaildb.com/api/json/v2/9973533/lookup.php?i=${$searchedCocktailId}`
    );

    const data = await resp.json();

    currentCocktail = data.drinks[0];

    let i = 1;
    let tmpIngredients = [];

    while (currentCocktail['strIngredient' + i] !== null) {
      tmpIngredients.push(
        `${currentCocktail['strIngredient' + i]}${
          currentCocktail['strMeasure' + i]
            ? '  •  ' + currentCocktail['strMeasure' + i]
            : ''
        }`
      );

      i++;
    }

    ingredients = tmpIngredients;
  };

  const closeDetail = () => searchedCocktailId.set(null);
</script>

<div
  class="hidden md:block md:w-full fixed top-0 left-0 h-screen z-50 bg-black opacity-10"
  out:fade={{ duration: 200 }}
  on:click={() => closeDetail()}
/>
<div
  class="sm:w-full md:w-1/4 bg-white fixed top-0 right-0 shadow-2xl z-50 overflow-auto h-screen"
  in:fly={{ x: 500, duration: 200 }}
  out:fly={{ x: 500, duration: 200 }}
>
  <div class="flex flex-col justify-between mb-4">
    {#if currentCocktail}
      <div class="flex flex-col">
        <img
          class="h-auto w-full object-cover shadow-xl"
          src={currentCocktail.strDrinkThumb}
          alt=""
        />
        <div class="p-4">
          <p class="uppercase tracking-wide text-sm font-bold text-gray-700">
            {currentCocktail.strCategory} • {currentCocktail.strGlass}
          </p>
          <div class="mt-2 mb-2 text-3xl">
            {currentCocktail.strDrink}
          </div>
        </div>
        <div class="p-4 border-t border-b text-xs text-gray-700">
          {#each ingredients as ingredient}
            <span class="flex items-center mb-1">
              {ingredient}
            </span>
          {/each}
        </div>
        <div class="p-4">
          <p class="text-sm">
            {currentCocktail.strInstructions}
          </p>
        </div>
      </div>

      <button
        class="py-2 px-4 mx-4 mb-4 bg-red-800 focus:ring-red-800 focus:ring-red-yellow-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 rounded"
        on:click={() => closeDetail()}>Close</button
      >
    {/if}
  </div>
</div>
