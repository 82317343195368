import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { currentUser } from '../store';
import { getFavouriteCoktails, resetFavouriteCocktails, } from './favourite.service';
export const signout = async () => {
    await signOut(auth);
};
auth.onAuthStateChanged(function (user) {
    if (user) {
        currentUser.set(user);
        getFavouriteCoktails(user);
        console.log(user);
    }
    else {
        currentUser.set(null);
        resetFavouriteCocktails();
    }
});
