<script lang="ts">
  import { useNavigate } from 'svelte-navigator';
  import { fly, fade } from 'svelte/transition';
  import type { Ingredient } from '../models/ingredient';

  import { selectedIngredient } from '../store';

  let currentIngredient: Ingredient;
  $: $selectedIngredient, getIngredientDetail();

  const navigate = useNavigate();
  const getIngredientDetail = async () => {
    if (!$selectedIngredient) {
      currentIngredient = <Ingredient>{};
      return;
    }

    const resp = await fetch(
      `https://www.thecocktaildb.com/api/json/v2/9973533/search.php?i=${$selectedIngredient}`
    );
    const data = await resp.json();

    currentIngredient = data.ingredients[0];
  };

  const closeDetail = () => selectedIngredient.set(null);
  const buyOnAmazon = () => {
    window.open(
      `https://www.amazon.it/s?k=${currentIngredient.strIngredient
        .split(' ')
        .join('+')}&ref=nb_sb_noss_2`,
      '_blank'
    );
  };
</script>

<div
  class="hidden md:block md:w-full fixed top-0 left-0 h-screen z-50"
  style="backdrop-filter: blur(3px)"
  out:fade={{ duration: 400 }}
  on:click={() => closeDetail()}
/>
<div
  class="sm:w-full md:w-1/2 lg:w-1/4 bg-white fixed top-0 right-0 shadow-2xl z-50 overflow-auto h-screen"
  in:fly={{ x: 500, duration: 400 }}
  out:fly={{ x: 500, duration: 400 }}
>
  <div class="flex flex-col justify-between mb-4">
    <div class="flex flex-col">
      {#if currentIngredient && currentIngredient.strIngredient}
        <img
          class="h-auto w-full object-cover shadow-xl"
          src={`http://www.thecocktaildb.com/images/ingredients/${currentIngredient.strIngredient}.png`}
          alt=""
        />
        <div class="p-4">
          <p class="uppercase tracking-wide text-sm font-bold text-gray-700">
            {currentIngredient.strType || 'No type available'} • Alcohol: {currentIngredient.strAlcohol ||
              'No'}
            {currentIngredient.strABV
              ? '(' + currentIngredient.strABV + '%' + ')'
              : ''}
          </p>
          <div class="mt-2 mb-2 text-3xl">
            {currentIngredient.strIngredient}
          </div>
        </div>
        <!-- <div class="p-4 border-t border-b text-xs text-gray-700">
        
      </div> -->
        <div class="p-4">
          <p class="text-sm">
            {currentIngredient.strDescription || 'No description available'}
          </p>
        </div>
      {/if}
    </div>

    <button
      class="py-2 px-4 mb-4 mx-4 bg-red-800 hover:bg-red-900 focus:ring-red-800 focus:ring-offset-red-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2  rounded"
      on:click={() =>
        navigate(`/search-cocktail/${currentIngredient.strIngredient}`)}
      >View Recipes</button
    >

    <button
      class="py-2 px-4 mb-4 mx-4 bg-yellow-500 hover:bg-yellow-900 focus:ring-yellow-800 focus:ring-offset-yellow-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2  rounded"
      on:click={() => buyOnAmazon()}>Buy on Amazon</button
    >

    <button
      class="py-2 px-4 mx-4 mb-8 bg-ocra hover:bg-yellow-500 focus:ring-yellow-500 focus:ring-offset-yellow-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2  rounded"
      on:click={() => closeDetail()}>Close</button
    >
  </div>
</div>
