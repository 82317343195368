<script lang="ts">
  import { fly, fade } from 'svelte/transition';
  import type { Cocktail } from '../models/cocktail';

  import {
    addCocktailToFavourites,
    removeCocktailFromFavourites,
  } from '../service/favourite.service';

  import { selectedCocktail, currentUser, favouriteCocktails } from '../store';
  import { onMount } from 'svelte';
  import Star from './ui/Star.svelte';

  import { setContext } from 'svelte';

  let currentCocktail: Cocktail = null;
  let ingredients: string[] = [];

  const closeDetail = () => selectedCocktail.set(null);

  // $: currentCocktail = $selectedCocktail;
  $: getIngredients($selectedCocktail);
  $: isFavourite = $favouriteCocktails.some(
    c => c.idDrink === currentCocktail?.idDrink
  );

  const getIngredients = selectedCocktail => {
    if (!selectedCocktail) {
      setTimeout(() => {
        currentCocktail = null;
        ingredients = [];
      }, 600);

      return;
    }

    currentCocktail = selectedCocktail;

    let i = 1;
    let tmpIngredients = [];

    while (selectedCocktail['strIngredient' + i] !== null) {
      tmpIngredients.push(
        `${selectedCocktail['strIngredient' + i]}${
          selectedCocktail['strMeasure' + i]
            ? '  •  ' + selectedCocktail['strMeasure' + i]
            : ''
        }`
      );

      i++;
    }

    ingredients = tmpIngredients;
  };

  const addToFavourites = () => {
    addCocktailToFavourites($currentUser, currentCocktail);
    favouriteCocktails.set([...$favouriteCocktails, currentCocktail]);
  };

  const removeFromFavourites = () => {
    removeCocktailFromFavourites($currentUser, currentCocktail);
    favouriteCocktails.set(
      $favouriteCocktails.filter(c => c.idDrink !== currentCocktail.idDrink)
    );
  };

  setContext('favFuncRef', addToFavourites);
  setContext('remFuncRef', removeFromFavourites);
</script>

<div
  class="hidden md:block md:w-full fixed top-0 left-0 h-screen z-50"
  style="backdrop-filter: blur(3px)"
  out:fade={{ duration: 400 }}
  on:click={() => closeDetail()}
/>
<div
  class="sm:w-full md:w-1/4 bg-white fixed top-0 right-0 shadow-2xl z-50 h-screen"
  in:fly={{ x: 500, duration: 250 }}
  out:fly={{ x: 500, duration: 400 }}
>
  <div class="flex flex-col justify-between mb-4 md:mb-0 md:h-full">
    <div class="flex flex-col">
      <img
        class="h-auto w-full object-cover shadow-xl"
        src={currentCocktail?.strDrinkThumb}
        alt=""
      />
      <div class="p-4">
        <p class="uppercase tracking-wide text-sm font-bold text-gray-700">
          {currentCocktail?.strCategory} • {currentCocktail?.strGlass}
        </p>
        <div class="mt-2 mb-2 text-3xl flex justify-between">
          <div>{currentCocktail?.strDrink}</div>
          {#if $currentUser}
            <Star
              on:favourite={() => addToFavourites()}
              on:remove={() => removeFromFavourites()}
              {isFavourite}
            />
          {/if}
        </div>
      </div>
      <div class="p-4 border-t border-b text-xs text-gray-700">
        {#each ingredients as ingredient}
          <span class="flex items-center mb-1">
            {ingredient}
          </span>
        {/each}
      </div>
      <div class="p-4">
        <p class="text-sm">
          {currentCocktail?.strInstructions}
        </p>
      </div>
    </div>
    <div class="flex flex-col">
      <!-- {#if $currentUser}
        <button
          class="py-2 px-4 mx-4 mb-4 bg-yellow-500 hover:bg-yellow-400 focus:ring-yellow-400 focus:ring-offset-yellow-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2  rounded"
          on:click={() =>
            isFavourite ? removeFromFavourites() : addToFavourites()}
          >{isFavourite
            ? 'Remove from Favourites'
            : 'Add to Favourites'}</button
        >
      {:else}
        <button
          class="py-2 px-4 mx-4 mb-4 bg-yellow-500 hover:bg-yellow-400 focus:ring-yellow-400 focus:ring-offset-yellow-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2  rounded opacity-60"
          disabled>Sign In to Add To Favourites</button
        >
      {/if} -->
      <button
        class="py-2 px-4 mx-4 mb-4 bg-red-800  focus:ring-red-800 focus:ring-red-yellow-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2  rounded"
        on:click={() => closeDetail()}>Close</button
      >
    </div>
  </div>
</div>
