<script lang="ts">
  import type { CocktailSearchResult } from '../models/cocktail-search-result';
  import { onMount } from 'svelte';
  import CocktailSearchCard from '../components/CocktailSearchCard.svelte';
  import { searchedCocktailId } from '../store';
  import CocktailSearchDetail from '../components/CocktailSearchDetail.svelte';

  export let cocktailQuery: string;

  let cocktails: CocktailSearchResult[] = [];

  const getCocktails = async () => {
    const resp = await fetch(
      `https://www.thecocktaildb.com/api/json/v2/9973533/filter.php?i=${cocktailQuery}`
    );
    const data = await resp.json();

    cocktails = data.drinks;
  };

  $: openDetail = $searchedCocktailId;

  onMount(() => getCocktails());
</script>

<div class="text-2xl text-center cocktail-search-header py-4">
  Cocktails with: <strong>{cocktailQuery}</strong>
</div>
<div class="cocktail-container">
  {#each cocktails as cocktail}
    <CocktailSearchCard {cocktail} />
  {/each}
</div>

{#if openDetail}
  <CocktailSearchDetail />
{/if}

<style lang="scss">
  .cocktail-container {
    margin-top: 5rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 25px;
    grid-auto-rows: 1fr;
  }

  .cocktail-search-header {
    position: fixed;
    top: 64px;
    right: 0;
    width: 100vw;
    z-index: 5;
    background-color: #F59E0B;
  }
</style>