<script lang="ts">
  import { Router, Route, Link } from 'svelte-navigator';
  import Cocktails from './Cocktails.svelte';
  import CocktailsSearch from './CocktailsSearch.svelte';
  import Ingredients from './Ingredients.svelte';
  import { signout } from '../service/auth.service';
  import { currentUser } from '../store';
  import FavouritesCocktails from './FavouritesCocktails.svelte';
  import Signin from './Signin.svelte';
  import PopularCocktails from './PopularCocktails.svelte';
  import Icon from 'svelte-awesome';
  import { circleONotch, spinner } from 'svelte-awesome/icons';

  // eslint-disable-next-line no-unused-vars
  const getProps = ({ location, href, isPartiallyCurrent, isCurrent }) => {
    // const isActive = href === '/' ? isCurrent : isPartiallyCurrent || isCurrent;

    // The object returned here is spread on the anchor element's attributes
    if (isCurrent) {
      return { class: 'active-link' };
    }
    return {};
  };

  let mobileMenuOpen = false;
  let signInFormVisible = false;
</script>

<Router url="/cocktails">
  <div class="bg-gray-900 sticky top-0 w-screen z-10">
    <div
      class="px-4 py-3 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8"
    >
      <div
        class="relative flex items-center justify-between"
        style="height: 40px;"
      >
        <a
          href="/"
          aria-label="Company"
          title="Company"
          class="inline-flex items-center"
        >
          <img
            class="absolute w-64"
            src="/images/logo.png"
            alt="My Awesome Cocktail Recipes"
          />
        </a>
        <ul class="flex items-center hidden space-x-8 lg:flex">
          <li>
            <Link to="/" {getProps}>
              <div
                class="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
              >
                Popular
              </div>
            </Link>
          </li>
          <li>
            <Link to="/search" {getProps}>
              <div
                class="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
              >
                Search
              </div>
            </Link>
          </li>
          <li>
            <Link to="/ingredients" {getProps}>
              <div
                class="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
              >
                Ingredients
              </div>
            </Link>
          </li>
          {#if $currentUser}
            <li>
              <Link to="/favourites" {getProps}>
                <div
                  class="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
                >
                  Favourites
                </div>
              </Link>
            </li>
          {/if}
        </ul>
        <ul class="items-center hidden space-x-8 md:flex">
          <li>
            {#if $currentUser}
              <div class="flex items-center">
                <img
                  class="w-8 h-8 rounded-full cursor-pointer bg-white mr-4"
                  src={$currentUser.photoURL || 'images/user-icon.png'}
                  alt="User Phoyo"
                />
                <span
                  class="font-extralight tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400 mr-4"
                  >{$currentUser.displayName.split(' ')[0]}</span
                >

                <button
                  class="py-2 px-4 bg-transparent text-center text-base font-semibold text-ocra"
                  on:click={() => signout()}
                >
                  Sign out
                </button>
              </div>
            {:else if typeof $currentUser === 'undefined'}<Icon
                data={circleONotch}
                spin
                scale={1.3}
                class="text-white"
              />
            {:else}
              <button
                class="py-2 px-4 bg-transparent text-center text-base font-semibold text-white"
                on:click={() => (signInFormVisible = !signInFormVisible)}
              >
                Sign In
              </button>
              {#if signInFormVisible}
                <div class="absolute top-0 right-0 w-96 z-50">
                  <div class="p-5 bg-gray-900 border rounded shadow-sm">
                    <div class="flex items-center justify-between mb-4">
                      <div>
                        <img
                          class="w-64"
                          src="/images/logo.png"
                          alt="My Awesome Cocktail Recipes"
                        />
                      </div>
                      <div>
                        <button
                          aria-label="Close Menu"
                          title="Close Menu"
                          class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                          on:click={() => (signInFormVisible = false)}
                        >
                          <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <Signin />
                  </div>
                </div>
              {/if}
            {/if}
          </li>
        </ul>
        <!-- Mobile menu -->
        <div class="lg:hidden">
          <button
            aria-label="Open Menu"
            title="Open Menu"
            class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline"
            on:click={() => (mobileMenuOpen = !mobileMenuOpen)}
          >
            <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
              />
              <path
                fill="currentColor"
                d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
              />
              <path
                fill="currentColor"
                d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
              />
            </svg>
          </button>
          {#if mobileMenuOpen}
            <div class="absolute top-0 left-0 w-full z-50">
              <div class="p-5 bg-gray-900 border rounded shadow-sm">
                <div class="flex items-center justify-between mb-4">
                  <div>
                    <img
                      class="w-64"
                      src="/logo.png"
                      alt="My Awesome Cocktail Recipes"
                    />
                  </div>
                  <div>
                    <button
                      aria-label="Close Menu"
                      title="Close Menu"
                      class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                      on:click={() => (mobileMenuOpen = false)}
                    >
                      <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <nav>
                  <ul class="space-y-4">
                    <li>
                      <Link to="/" {getProps}>
                        <div
                          class="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
                          on:click={() => (mobileMenuOpen = false)}
                        >
                          Popular
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/search" {getProps}>
                        <div
                          class="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
                          on:click={() => (mobileMenuOpen = false)}
                        >
                          Search
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/ingredients" {getProps}>
                        <div
                          class="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
                          on:click={() => (mobileMenuOpen = false)}
                        >
                          Ingredients
                        </div>
                      </Link>
                    </li>
                    {#if $currentUser}
                      <li>
                        <Link to="/favourites" {getProps}>
                          <div
                            class="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
                            on:click={() => (mobileMenuOpen = false)}
                          >
                            Favourites
                          </div>
                        </Link>
                      </li>
                    {/if}
                    <li>
                      {#if $currentUser}
                        <div class="flex items-center">
                          <img
                            class="w-8 h-8 rounded-full cursor-pointer bg-white mr-4"
                            src={$currentUser.photoURL ||
                              'images/user-icon.png'}
                            alt="User"
                          />
                          <span
                            class="font-extralight tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400 mr-4"
                            >{$currentUser.displayName.split(' ')[0]}</span
                          >
                        </div>
                        <button
                          class="py-2 bg-transparent text-center text-base font-semibold text-ocra mt-2"
                          on:click={() => signout()}
                        >
                          Sign out
                        </button>
                      {:else if typeof $currentUser === 'undefined'}<span />
                      {:else}
                        <Signin />
                      {/if}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          {/if}
        </div>
      </div>
    </div>
  </div>

  <main class="md:px-24 pb-16">
    <Route path="/" primary={false}>
      <PopularCocktails />
    </Route>
    <Route path="/search" primary={false}>
      <Cocktails />
    </Route>
    <Route path="/ingredients" primary={false}>
      <Ingredients />
    </Route>
    <Route path="/favourites" primary={false}>
      <FavouritesCocktails />
    </Route>
    <!-- <Route path="/sign-in" primary={false}>
      <Signin />
    </Route> -->
    <Route path="/search-cocktail/:cocktailQuery" let:params primary={false}>
      <CocktailsSearch cocktailQuery={params.cocktailQuery} />
    </Route>
  </main>
  <footer
    class="fixed bottom-0 w-screen bg-gray-900 text-white text-xs text-center"
  >
    Developed by Edoardo L. Pacciani
  </footer>
</Router>
