<script lang="ts">
  import Icon from 'svelte-awesome';
  import { star, starO } from 'svelte-awesome/icons';
  import { Link } from 'svelte-navigator';

  import type { Cocktail } from '../models/cocktail';
  import { favouriteCocktails, selectedCocktail } from '../store';

  export let cocktail: Cocktail;

  const selectCocktail = () => {
    selectedCocktail.set(cocktail);
  };

  $: isFavourite = $favouriteCocktails.some(
    c => c.idDrink === cocktail.idDrink
  );
</script>

<div class="flex items-center justify-center">
  <div class="max-w-sm w-full py-2 px-3">
    <div
      class="bg-white shadow-xl rounded-lg overflow-hidden cursor-pointer"
      on:click={() => selectCocktail()}
    >
      <div class="overflow-hidden">
        <figure class="h-56 w-full">
          <img
            class="cocktail-img"
            src={cocktail.strDrinkThumb}
            alt="faboulous cocktail"
          />
        </figure>
      </div>
      <div class="p-4">
        <div class="flex justify-between">
          <p class="uppercase tracking-wide text-sm font-bold text-gray-700">
            {cocktail.strCategory}
          </p>
          {#if isFavourite}
            <Icon data={star} scale={1.3} class="text-yellow-500" />
          {:else}<Icon data={starO} scale={1.3} class="cursor-pointer" />
          {/if}
        </div>

        <p class="text-3xl mb-2 text-gray-900">
          {cocktail.strDrink}
        </p>
        <div class="flex flex-wrap overflow-hidden" style="max-height: 37px">
          {#if cocktail.strIngredient1}
            <Link to={`/search-cocktail/${cocktail.strIngredient1}`}>
              <div
                class="inline-block px-3 py-1 my-1 mr-2 text-sm font-semibold text-white bg-yellow-500 rounded-full"
              >
                {cocktail.strIngredient1}
              </div>
            </Link>
          {/if}
          {#if cocktail.strIngredient2}
            <Link to={`/search-cocktail/${cocktail.strIngredient2}`}>
              <div
                class="inline-block px-3 py-1 my-1 mr-2 text-sm font-semibold text-white bg-yellow-500 rounded-full"
              >
                {cocktail.strIngredient2}
              </div>
            </Link>
          {/if}
          {#if cocktail.strIngredient3}
            <Link to={`/search-cocktail/${cocktail.strIngredient3}`}>
              <div
                class="inline-block px-3 py-1 my-1 mr-2 text-sm font-semibold text-white bg-yellow-500 rounded-full"
              >
                {cocktail.strIngredient3}
              </div>
            </Link>
          {/if}
        </div>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  .cocktail-img {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    &:hover {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
</style>
